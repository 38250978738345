import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { Button, File as FileComponent, Grid } from "@app/components";
import { useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import {
  APIResponse,
  attachSmartContractFile,
  generateSmartContractFile,
  recallApprovalSmartContract,
  sendToSignSmartContractFile,
} from "@app/api";
import { SmartContract, SmartContractStatus } from "@app/models";
import { theme } from "styled-tools";
import { FilesPickerDropzone } from "../../../../components/FilesPicker/components";
import { ContractTranscriptView, PreviewModal } from "@app/common";

interface Props {
  contract: SmartContract;
  onChange: (step: number) => void;
  setModalActions: Dispatch<SetStateAction<ReactNode[]>>;
  updateContract: (
    partialContract: Partial<SmartContract>,
    refresh?: boolean
  ) => void;
}

const StyledStep4 = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: 1fr auto;
`;

const StyledContent = styled.div``;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 16px;
  margin: 16px 0;
  font-size: 14px;
  line-height: 20px;
  color ${theme("color.grayDark")};
`;

const StyledContractRow = styled.div<{ isSigned?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 16px;
  background-color: ${(props) => (props.isSigned ? "#F5F6F8" : "transparent")};
  border-radius: 4px;
`;

function Step4(props: Props) {
  const {
    onChange: onChangeStep,
    contract,
    updateContract,
    setModalActions,
  } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [currentFile, setCurrentFile] = useState<Blob | null>(null);

  const onClickPrev = useCallback(() => {
    onChangeStep(3);
  }, [onChangeStep]);

  const onClickNext = useCallback(() => {
    onChangeStep(5);
  }, [onChangeStep]);

  const onClickSendToSign = useCallback(async () => {
    try {
      setPending(true);

      const response = await sendToSignSmartContractFile(contract.id);

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Smart-договор отправлен на подписание",
        variant: "success",
      });

      updateContract({
        status: SmartContractStatus.Singing,
      });
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [contract.id, showNotification, updateContract]);

  const onClickGenerate = useCallback(async () => {
    try {
      setPending(true);

      const response = await generateSmartContractFile(contract.id);

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Файл договора сформирован",
        variant: "success",
      });

      updateContract({
        file: response.data.file,
        pdf: response.data.pdf,
        files: response.data.files,
        pdfFiles: response.data.pdfFiles,
        signFiles: response.data.signFiles,
        signedPdfFiles: response.data.signedPdfFiles,
      });
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [contract.id, showNotification, updateContract]);

  const onClickRecallApproval = useCallback(async () => {
    try {
      setPending(true);

      const response = await recallApprovalSmartContract(contract.id);

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Smart-договор отозван",
        variant: "success",
      });

      updateContract(
        {
          status: SmartContractStatus.Revoked,
        },
        true
      );
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [contract.id, showNotification, updateContract]);

  const onChangeFiles = useCallback(
    (_files: File[]) => {
      if (_files.length && _files[0].size > 10 * 1024 * 1024) {
        showNotification({
          message: "Размер файла не должен превышать 10 Мб",
          variant: "error",
        });
      } else {
        setUploadFiles(_files);
      }
    },
    [showNotification]
  );

  const onFileSave = useCallback(async () => {
    try {
      setPending(true);

      const response = await attachSmartContractFile(
        contract.id,
        uploadFiles[0]
      );

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      setUploadFiles([]);

      showNotification({
        message: "Файл договора прикреплен",
        variant: "success",
      });

      // setContractFiles([response.data.file, response.data.pdf]);

      updateContract({
        file: response.data.file,
        pdf: response.data.pdf,
        files: response.data.files,
        pdfFiles: response.data.pdfFiles,
        signFiles: response.data.signFiles,
        signedPdfFiles: response.data.signedPdfFiles,
      });
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [contract.id, uploadFiles, showNotification, updateContract]);

  const onTempFileDelete = useCallback(() => setUploadFiles([]), []);

  useEffect(() => {
    const modalActions: ReactNode[] = [];

    if (
      [
        SmartContractStatus.Draft,
        SmartContractStatus.Revoked,
        SmartContractStatus.Rejected,
      ].indexOf(contract.status) > -1
    ) {
      modalActions.push(
        <Button
          text="Отправить на подписание"
          variant="contained"
          onClick={onClickSendToSign}
          disabled={pending}
        />
      );
    }

    if (
      [
        SmartContractStatus.OnApproval,
        SmartContractStatus.Singing,
        SmartContractStatus.CustomerSigned,
        SmartContractStatus.PartnerSigned,
      ].indexOf(contract.status) > -1
    ) {
      modalActions.push(
        <Button
          text="Отозвать"
          variant="outlined"
          onClick={onClickRecallApproval}
          disabled={pending}
        />
      );
    }

    setModalActions(modalActions);
  }, [
    contract.status,
    onClickGenerate,
    onClickRecallApproval,
    onClickSendToSign,
    pending,
    setModalActions,
  ]);

  const contractFiles = () => {
    const arr = contract.files?.length ? contract.files : [contract.file];
    return [...arr].filter((item) => item?.isVisible);
  };

  return (
    <StyledStep4>
      <StyledContent>
        <Grid columns={2}>
          <div>
            <StyledContractRow>
              <h6>Сформируйте или загрузите файл</h6>
              <StyledCentered>
                <Button
                  text="Сформировать файл"
                  size="small"
                  onClick={onClickGenerate}
                  disabled={pending}
                  showLoader={pending}
                />
                <p>или</p>
              </StyledCentered>
            </StyledContractRow>
            {uploadFiles.length === 0 ? (
              <FilesPickerDropzone
                files={uploadFiles}
                onChange={onChangeFiles}
                multiple={false}
                comments={[
                  "Размер файла не должен превышать 10 МБ",
                  "Загрузить можно только форматы .doc и .docx",
                ]}
                accept={
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
              />
            ) : (
              <FileComponent
                file={uploadFiles[0]}
                fileIndex={0}
                loading={pending}
                actionTitle={"Сохранить файл"}
                onActionClick={onFileSave}
                onDelete={onTempFileDelete}
              />
            )}
          </div>
          <div>
            {contract && (
              <ContractTranscriptView
                files={contractFiles()}
                pdfFiles={
                  contract.pdfFiles?.length ? contract.pdfFiles : [contract.pdf]
                }
                signedPdfFiles={
                  contract.signedPdfFiles?.length
                    ? contract.signedPdfFiles
                    : [contract.signedPdf]
                }
                otherFiles={contract.otherFiles}
                filesTitle="Файл договора"
                pdfFilesTitle="Сформированный договор"
                signedPdfFilesTitle="Подписанный договор"
              />
            )}
          </div>
        </Grid>
      </StyledContent>
      <StyledFooter>
        <Button onClick={onClickPrev} text="Назад" variant="outlined" />
        <Button onClick={onClickNext} text="Далее" />
      </StyledFooter>

      <PreviewModal
        open={!!currentFile}
        file={currentFile}
        onClose={() => setCurrentFile(null)}
      />
    </StyledStep4>
  );
}

export default memo(Step4);
