import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "@app/components";
import styled from "styled-components";
import { useDragScroll } from "@app/hooks";

type Props = {
  open: boolean;
  file: Blob | null;
  onClose: () => void;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 700px;
  position: relative;

  overflow: auto;

  cursor: grab;
  touch-action: none;
`;

const StyledControls = styled.div`
  position: fixed;
  display: flex;
  gap: 4px;
  padding: 8px;
  border-radius: 16px;
  background-color: rgba(27, 27, 27, 0.33);

  p {
    width: 48px;
    color: white;
    text-align: center;
  }
`;

const StyledIFrame = styled.iframe`
  border: none;
  zoom: 125%;
`;

const StyledImage = styled.img<{ zoom: number }>`
  transform-origin: top left;
  transform: scale(${(props) => props.zoom});
`;

const PreviewModal = (props: Props) => {
  const { open, file, onClose } = props;
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [zoom, setZoom] = useState(1);

  const { ref, node } = useDragScroll();

  const url = useMemo(() => (file ? URL.createObjectURL(file) : null), [file]);
  const isImage = useMemo(() => {
    return /image/.test(file?.type || "");
  }, [file?.type]);

  const increaseZoom = useCallback(() => {
    if (zoom >= 2) {
      return;
    }
    setZoom((prevState) => prevState + 0.25);
  }, [zoom]);

  const decreaseZoom = useCallback(() => {
    const containerWidth = node?.offsetHeight || 0;
    const imageScaledWidth =
      imageRef?.current?.getBoundingClientRect().height || 0;
    if (zoom <= 0.5 || imageScaledWidth < containerWidth) {
      return;
    }
    setZoom((prevState) => prevState - 0.25);
  }, [node, zoom]);

  useEffect(() => {
    setZoom(1);
  }, [open]);

  return (
    <Modal
      open={open}
      title={"..."}
      position={"center"}
      customSize="1120px"
      hideHeader
      zIndex={11000}
      onClose={onClose}
    >
      <StyledContainer ref={ref}>
        {url &&
          (isImage ? (
            <StyledImage
              ref={imageRef}
              src={url}
              zoom={zoom}
              alt={"Preview document"}
              draggable="false"
            />
          ) : (
            <>
              <StyledIFrame
                src={url}
                title={"title"}
                name={"name"}
                height={800}
                width={"100%"}
              />
            </>
          ))}
        {isImage && (
          <StyledControls>
            <Button
              variant={"contained"}
              text={"-"}
              size={"small"}
              onClick={decreaseZoom}
            />
            <p>{zoom}x</p>
            <Button
              variant={"contained"}
              text={"+"}
              size={"small"}
              onClick={increaseZoom}
            />
          </StyledControls>
        )}
      </StyledContainer>
    </Modal>
  );
};

export default memo(PreviewModal);
