import {
  ActDetail,
  ActOfWork,
  ActOfWorkNomenclature,
  CompanyShort,
  SmartContract,
  VehicleJournalForActV2,
} from "@app/models";
import {
  getActOfWorkNomenclatureName,
  getActOfWorkNomenclaturePrice,
  getActOfWorkNomenclatureQuantity,
} from "./nomenclature";
import { ActOfProvidedWorkDTO } from "@app/api";

export const getCompanyObject = (company: CompanyShort | undefined | null) => {
  if (!company) {
    return null;
  }

  return {
    ...company,
    name: company.name ?? "",
    bin: company.bin ?? "",
  };
};

const getNomenclatureItem = (
  item: VehicleJournalForActV2,
  endWorkDate: string
) => ({
  avrId: "",
  name: getActOfWorkNomenclatureName(item),
  executionDate: endWorkDate,
  // unit: item.unitDto,
  quantity: item.totalToPay ?? getActOfWorkNomenclatureQuantity(item),
  price: item.totalPrice ?? getActOfWorkNomenclaturePrice(item),
  sum: item.totalSumma,
  sumNds: item.totalSummaNds || 0,
});

export const getActNomenclatures = (
  vehicleJournal: VehicleJournalForActV2[],
  endWorkDate: string
): ActOfWorkNomenclature[] => {
  return vehicleJournal.map((item) => {
    const nomenclature = getNomenclatureItem(item, endWorkDate);
    return {
      ...nomenclature,
      unit: item.unitDto,
    };
  });
};

export const getActNomenclaturesForCreate = (
  vehicleJournal: VehicleJournalForActV2[],
  endWorkDate: string
): ActOfProvidedWorkDTO["nomenclatures"] => {
  return vehicleJournal.map((item) => {
    const nomenclature = getNomenclatureItem(item, endWorkDate);
    return {
      ...nomenclature,
      price: nomenclature.price ?? 0,
      quantity: nomenclature.quantity ?? 0,
      sum: nomenclature.sum ?? 0,
      unitId: item.unitDto.id,
    };
  });
};

export const getActOfWorkInit = (act: Partial<ActDetail>): ActOfWork => {
  return {
    id: "",
    createdDate: new Date().toString(),
    number: null,
    registrationNumber: "",
    invoiceNumber: "",
    initiatorName: "",
    date: null,
    startDate: act.startWorkDate ?? "",
    endDate: act.endWorkDate ?? "",
    senderCompanyAddress: "",
    recipientCompanyAddress: "",
    ndsRate: act.nds?.name ?? null,
    statusId: null,
    actId: act.id ?? "",
    actNumber: act.actNumber ?? "",
    senderCompany: getCompanyObject(act.partner)!,
    recipientCompany: getCompanyObject(act.company)!,
    project: act.project!,
    contract: act.contract as SmartContract,
    signedFile: null,
    totalQuantity: null,
    totalSum: 0,
    totalSumNds: 0,
    files: [],
    signers: [],
    histories: [],
    approvers: [],
    basis: null,
    nomenclatures: [],
  };
};
