import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AxiosError } from "axios";
import {
  Button,
  Checkbox,
  Grid,
  Modal,
  ModalProps,
  ModalTitle,
  Tabs,
} from "@app/components";
import "./styles.scss";
import ListItem from "../../components/List/ListItem";
import {
  SmartContract,
  Task,
  UserTaskStatuses,
  UserTaskType,
} from "@app/models";
import {
  APIResponse,
  getSingingDocumentBase64,
  getSmartContract,
  getTaskById,
  getXmlSignFile,
  signMultiSmartContract,
  signSmartContract,
  SignSmartContractV2DTO,
  signXmlSmartContract,
} from "@app/api";
import * as ncaLayer from "@app/helpers";
import { dateFormat, getAxiosErrorMessage, getFullName } from "@app/helpers";
import { IconPrint16 } from "@app/icons";
import { useNcaLayerClient, useNotification } from "@app/providers";
import { ContractorDocuments } from "@app/common";
import { ContractDetailView } from "./components";
import ModalSigning from "../Signing";
import TaskStatusChip from "../../common/TaskStatusChip";

interface Props extends Omit<ModalProps, "title"> {
  data: Task | null;
  onUserRedirect: () => void;
  onApprove: () => void;
  onReject: () => void;
  onClose: (val?: boolean) => void;
}

function ModalDocumentDetail(props: Props) {
  const { onClose, data, onUserRedirect, ...restProps } = props;
  const { showNotification } = useNotification();
  const { isRunning, signCMS } = useNcaLayerClient();
  const [showSigning, setShowSigning] = useState<boolean>(false);
  const [isNewSigning, setNewSigning] = useState<boolean>(false);
  // const [taskDetail, setTaskDetail] = useState<Task | null>(null);
  const [contractDetail, setContractDetail] = useState<SmartContract | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onPrintButtonClick = useCallback(() => {
    if (!data) {
      return;
    }
    window.open(
      `./contracts/printing/${data.sourceId}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, [data]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!contractDetail) {
      return "Загружается...";
    }

    return (
      <ModalTitle text={data?.name || ""}>
        {!!data?.status && <TaskStatusChip status={data.status} />}
        {!!contractDetail.status && (
          <Button
            text={"Печатная форма"}
            size={"small"}
            variant={"outlined"}
            startIcon={IconPrint16}
            onClick={onPrintButtonClick}
          />
        )}
      </ModalTitle>
    );
  }, [contractDetail, data, onPrintButtonClick]);

  const getContractDetail = useCallback(
    async (sourceId: string) => {
      setLoading(true);
      try {
        const response = await getSmartContract(sourceId);
        setContractDetail(response.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  const getTaskDetail = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response = await getTaskById(id);
        setLoading(false);
        if (response.data) {
          // setTaskDetail(response.data);
          getContractDetail(response.data.sourceId).then(() => {});
        }
      } catch (e) {
        setLoading(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [getContractDetail, showNotification]
  );

  useEffect(() => {
    if (!data) return;
    getContractDetail(data.sourceId).then(() => {});
  }, [data, getContractDetail]);

  const tabLabels = useMemo<string[]>(
    () => ["Договор оказания услуг техники", "Документы исполнителя"],
    []
  );

  const onModalSigningSuccess = useCallback(() => {
    if (!data) return;
    getTaskDetail(data.id).then(() => {});
  }, [data, getTaskDetail]);

  const convertURLToBase64 = async (url: string) => {
    return await fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise<string>((res) => {
          reader.onloadend = () => {
            res(reader.result as string);
          };
        });
      });
  };

  const signContract = useCallback(
    async (callbackData: any, isXml = false) => {
      if (callbackData && callbackData.responseObject && data?.id) {
        setLoading(true);
        const body = {
          id: data.id,
          signedFile: callbackData.responseObject,
        };
        try {
          let res;
          if (isXml) {
            res = await signXmlSmartContract(body);
          } else {
            res = await signSmartContract(body);
          }
          setLoading(false);

          if (res && res.succeeded) {
            showNotification({
              message: "Договор подписан успешно",
              variant: "success",
            });
            onModalSigningSuccess();
            onClose(true);
          }
        } catch (e) {
          setLoading(false);

          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        }
      }
    },
    [data, onClose, onModalSigningSuccess, showNotification]
  );

  const signingV2 = useCallback(async () => {
    if (!isRunning) {
      return;
    }
    try {
      const { signFiles, pdfFiles } = contractDetail!;
      const files = (signFiles?.length ? signFiles : pdfFiles) ?? [];
      const filteredFiles = files.filter((item) => !!item.url);
      const promises = files.map((file) => getSingingDocumentBase64(file.url!));

      const documentsBase64 = await Promise.all(promises);
      const signedCmsFiles = await signCMS(documentsBase64);
      const signedFiles = signedCmsFiles.map((signedFile, sfIndex) => ({
        fileId: filteredFiles[sfIndex].id,
        signedFile,
      }));
      const body: SignSmartContractV2DTO = {
        id: data!.id,
        signedFiles,
      };
      const res = await signMultiSmartContract(body);

      if (res.succeeded) {
        showNotification({
          message: "Договор подписан успешно (массив CMS)",
          variant: "success",
        });
      }
      onClose(true);
    } catch (e) {
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [contractDetail, data, isRunning, onClose, showNotification, signCMS]);

  const onClickSave = useCallback(async () => {
    if (!contractDetail || !data) {
      return;
    }

    if (isNewSigning) {
      await signingV2();
      return;
    }

    if (!ncaLayer.checkWebsocket()) {
      ncaLayer.initializeLayer();
    }

    await getContractDetail(contractDetail.id);

    const storageType = {
      type: "PKCS12",
      name: "Компьютер",
    };

    const signType = {
      type: String,
      default: "SIGNATURE",
    };

    if (contractDetail.signFile || contractDetail.file) {
      const urlToSign =
        contractDetail.signFile?.url || contractDetail.file?.url;
      const base64 = await convertURLToBase64(urlToSign!);

      const cb = async (result: any) => {
        await signContract(result);
      };

      ncaLayer.createCAdESFromBase64(
        storageType.type,
        signType.default,
        base64.split(",").pop(),
        true,
        cb
      );
    } else if (contractDetail.files?.length) {
      const response = await getXmlSignFile(contractDetail.id);

      const cb = async (result: any) => {
        await signContract(result, true);
      };

      ncaLayer.signXml(
        storageType.type,
        signType.default,
        response.message,
        cb
      );
    }
  }, [
    contractDetail,
    data,
    getContractDetail,
    isNewSigning,
    signContract,
    signingV2,
  ]);

  const onClickRedirect = useCallback(() => {
    if (!data) {
      return;
    }
    onUserRedirect();
  }, [data, onUserRedirect]);

  const onClickRejectContract = useCallback(() => {
    if (!data) {
      return;
    }

    props.onReject();
  }, [data, props]);

  const onClickApprove = useCallback(() => {
    if (!data) {
      return;
    }

    props.onApprove();
  }, [data, props]);

  const onModalDetailClose = useCallback(() => {
    setContractDetail(null);
    onClose();
  }, [onClose]);

  const onModalSigningClose = useCallback(() => {
    setShowSigning(false);
  }, []);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    if (
      data &&
      [
        UserTaskStatuses.Agreed,
        UserTaskStatuses.Rejected,
        UserTaskStatuses.Redirected,
      ].includes(data.status)
    ) {
      return returnActions;
    }
    if (data?.taskType === UserTaskType.SigningContract) {
      returnActions.push(
        <Checkbox
          label={"Подписание массива CMS"}
          checked={isNewSigning}
          onChange={() => setNewSigning((v) => !v)}
        />,
        <Button
          text="Отклонить"
          showLoader={loading}
          variant={"outlined"}
          onClick={onClickRejectContract}
        />,
        <Button text="Подписать" onClick={onClickSave} showLoader={loading} />
      );
    } else if (data?.taskType === UserTaskType.ApprovalContract) {
      returnActions.push(
        <Button
          text="Перенаправить"
          showLoader={loading}
          variant={"text"}
          onClick={onClickRedirect}
        />,
        <Button
          text="Отклонить"
          showLoader={loading}
          variant={"outlined"}
          onClick={onClickRejectContract}
        />,
        <Button
          text="Согласовать"
          showLoader={loading}
          onClick={onClickApprove}
        />
      );
    }
    return returnActions;
  }, [
    data,
    isNewSigning,
    loading,
    onClickApprove,
    onClickRedirect,
    onClickRejectContract,
    onClickSave,
  ]);

  return (
    <Modal
      onClose={onModalDetailClose}
      title={modalTitle}
      actions={actions}
      dense
      {...restProps}
    >
      <div className="m-document-detail__grid">
        <Grid columns={5}>
          <ListItem
            subtitle="Организация"
            title={data?.customer?.name}
            reverse
          />
          <ListItem subtitle="Проект" title={data?.project?.name} reverse />
          <ListItem
            subtitle="Исполнитель"
            title={data?.executor.name}
            reverse
          />
          <ListItem
            subtitle="Инициатор"
            title={getFullName(data?.initiator)}
            reverse
          />
          <ListItem
            subtitle="Дата отправки"
            title={
              data?.dateStart ? dateFormat(data?.dateStart, "dd.MM.yyyy") : "-"
            }
            reverse
          />
        </Grid>
      </div>
      <Tabs labels={tabLabels}>
        <ContractDetailView contractData={contractDetail} loading={loading} />
        <ContractorDocuments contractData={contractDetail} />
      </Tabs>
      <ModalSigning
        open={showSigning}
        onClose={onModalSigningClose}
        onSignSuccess={onModalSigningSuccess}
        task={data}
        fileUrl={contractDetail?.file?.url}
      />
    </Modal>
  );
}

export default memo(ModalDocumentDetail);
