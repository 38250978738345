import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

type NcaLayerClientContextValue = {
  isRunning: boolean;
  signCMS: (base64Files: string[]) => Promise<string[]>;
};

const NcaLayerClientContext = createContext<NcaLayerClientContextValue>(
  {} as NcaLayerClientContextValue
);

export function NcaLayerClientProvider({ children }: PropsWithChildren) {
  const [isRunning, setIsRunning] = useState(false);
  const connectionInterval = useRef<NodeJS.Timeout | null>(null);
  const webSocket = useRef<WebSocket | null>(null);
  let attempts = 0;

  function createWebSocket() {
    webSocket.current = new WebSocket("wss://127.0.0.1:13579/");
    ++attempts;

    webSocket.current.onopen = () => {
      setIsRunning(true);

      if (connectionInterval.current) {
        clearInterval(connectionInterval.current);
      }
    };

    webSocket.current.onerror = () => {
      setIsRunning(false);
    };

    webSocket.current.onclose = () => {
      setIsRunning(false);
      attemptReconnect();
    };
  }

  function attemptReconnect() {
    if (!connectionInterval.current) {
      connectionInterval.current = setInterval(() => {
        if (
          (!webSocket.current ||
            webSocket.current.readyState === WebSocket.CLOSED) &&
          attempts < 5
        ) {
          createWebSocket();
        }
      }, 1000);
    }
  }

  function signCMS(base64Files: string[]): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const signInfo = {
        module: "kz.gov.pki.knca.basics",
        method: "sign",
        args: {
          format: "cms",
          allowedStorages: ["PKCS12"],
          data: base64Files,
          signingParams: { tsaProfile: {}, decode: true },
          signerParams: {
            extKeyUsageOids: ["1.3.6.1.5.5.7.3.4"],
          },
          locale: "ru",
        },
      };

      if (webSocket.current?.readyState === WebSocket.OPEN) {
        webSocket.current?.send(JSON.stringify(signInfo));

        webSocket.current.onmessage = ({ data }) => {
          const response = JSON.parse(data);

          if (response.status && response.body.result) {
            resolve(
              response.body.result.map((signedData: string) =>
                signedData
                  .replace("-----BEGIN CMS-----", "")
                  .replace("-----END CMS-----", "")
                  .trim()
              )
            );
          } else {
            console.error(response);
            reject(data);
          }
        };
      } else {
        reject("NCALayer не запущен");
      }
    });
  }

  useEffect(() => {
    createWebSocket();

    return () => {
      if (connectionInterval.current) {
        clearInterval(connectionInterval.current);
      }
    };
  }, []);

  return (
    <NcaLayerClientContext.Provider
      value={{
        isRunning,
        signCMS,
      }}
    >
      <>{children}</>
    </NcaLayerClientContext.Provider>
  );
}

export function useNcaLayerClient() {
  return useContext(NcaLayerClientContext);
}
