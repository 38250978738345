import {
  Chip,
  Grid,
  Modal,
  ModalProps,
  Select,
  SelectOption,
} from "@app/components";
import React, { useCallback, useMemo, useState } from "react";
import { useKIP } from "@app/providers";
import styled, { css } from "styled-components";
import {
  KIPCharacteristics,
  KIPPartners,
  KIPProject,
  KIPVehicle,
  KIPVehicleType,
} from "@app/models";
import { switchProp, theme } from "styled-tools";
import { VehicleStatistics } from "@app/common";
import { priceFormat } from "@app/helpers";
import Alert from "../../pages/KIP/components/Alert";
import { IconChevronUp24 } from "@app/icons";
import { ModalKIPInformation } from "../index";

interface Props extends Pick<ModalProps, "open" | "onClose"> {
  project: KIPProject | null;
  date: string;
}

const StyledHeader = styled.div`
  display: grid;
  gap: 24px;
  justify-items: center;
  margin-right: 48px;
`;

const StyledInfo = styled.div`
  width: 204px;
  text-align: center;
`;

const StyledLabel = styled.p`
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${theme("color.grayDark")};
`;

const StyledBox = styled.div`
  box-sizing: border-box;
  padding: 16px;
`;

const GroupedTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: ${theme("color.dark")};
  line-height: 24px;
`;

const StyledStat = styled.div<{
  variant: "default" | "warning" | "info" | "danger";
}>`
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  ${switchProp("variant", {
    default: css`
      color: ${theme("color.dark")};
      background-color: ${theme("color.grayLight")};
    `,
    warning: css`
      color: ${theme("color.dark")};
      background-color: ${theme("color.yellow")};
    `,
    info: css`
      color: ${theme("color.white")};
      background-color: ${theme("color.blueGreen")};
    `,
    danger: css`
      color: ${theme("color.white")};
      background-color: ${theme("color.red")};
    `,
  })}
  p {
    color: inherit;
    line-height: 16px;
    margin: 0;

    &:first-child {
      font-size: 12px;
      margin-bottom: 4px;
    }

    &:nth-child(2) {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

const StyledModalTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;
`;

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  max-width: 1256px;

  #select {
    width: 240px;
  }
`;

const CollapsedTitle = styled.div<{
  collapsed: boolean;
}>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 4px;
  background: ${theme("color.grayLight")};

  cursor: pointer;

  svg {
    transform: rotate(${(props) => (props.collapsed ? 180 : 0)}deg);
    transition: transform 0.4s ease-in-out;
  }

  div {
    display: flex;
    align-items: center;
    gap: 32px;
  }
`;

const CollapsedContent = styled.div<{
  collapsed: boolean;
}>`
  display: flex;
  padding: ${(props) => (props.collapsed ? "0 16px" : "12px 16px")};
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 0 0 4px 4px;
  border-right: 1px solid ${theme("color.grayLight")};
  border-bottom: 1px solid ${theme("color.grayLight")};
  border-left: 1px solid ${theme("color.grayLight")};
  background: ${theme("color.white")};
  margin-top: -4px; // чтобы бордеры прилегали к заголовку

  transition: max-height 0.4s ease-in-out, padding 0.4s;
  max-height: ${(props) => (props.collapsed ? 0 : "400px")};
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledGrid = styled.div<{ column: number; width: number }>`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: ${(props) =>
    `repeat(${props.column}, ${props.width}px)`};
  justify-content: center;
`;

const StyledInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1256px;
`;

function convertArray(array: Partial<KIPVehicle>[]): Partial<KIPVehicle>[][] {
  const result = [];
  for (let i = 0; i < array.length; i += 3) {
    result.push(array.slice(i, i + 3));
  }
  return result;
}

function Grouped(props: {
  title: string;
  data: KIPVehicleType | KIPPartners | KIPCharacteristics;
  project: KIPProject | null;
}) {
  const { title, data, project } = props;

  const [collapsed, setCollapsed] = useState(false);
  const onClick = useCallback(
    () => setCollapsed((prevState) => !prevState),
    []
  );

  return (
    <StyledBox>
      <div>
        <CollapsedTitle collapsed={collapsed} onClick={onClick}>
          <GroupedTitle>{title}</GroupedTitle>
          <div>
            <StyledStat variant="default">{data.vehiclesQty} ТС</StyledStat>

            <StyledStat variant="warning">
              {data.workLoad ? `${data.workLoad.toFixed(0)} %` : "-"}
            </StyledStat>
            <StyledStat variant="info">
              {priceFormat(data.costAmount.toFixed(0))}
            </StyledStat>

            <StyledStat variant="danger">
              {priceFormat(data.unusedAmount.toFixed(0))}
            </StyledStat>

            <IconChevronUp24 />
          </div>
        </CollapsedTitle>
        <CollapsedContent collapsed={collapsed}>
          <Grid columns={3} gap={16}>
            {data.vehicles.map((vehicle) => (
              <VehicleStatistics
                key={vehicle.id}
                projectId={project?.id}
                vehicle={vehicle as KIPVehicle}
              />
            ))}
          </Grid>
        </CollapsedContent>
      </div>
    </StyledBox>
  );
}

const groupTypes: SelectOption<null>[] = [
  {
    label: "Без группировки",
    value: 0,
    item: null,
  },
  {
    label: "По видам ТС",
    value: 1,
    item: null,
  },
  {
    label: "По партнерам",
    value: 2,
    item: null,
  },
  {
    label: "По характеристикам ТС",
    value: 3,
    item: null,
  },
];

export default function (props: Props) {
  const { open, onClose, project, date } = props;
  const [showModal, setShowModal] = useState(false);

  const { getVehicles, getVehicleTypes, getPartners, getCharacteristics } =
    useKIP();
  const [grouped, setGrouped] = useState<SelectOption<null> | null>(
    groupTypes[0]
  );

  const modalTitle = useMemo(() => {
    if (!project) {
      return "";
    }

    return <StyledModalTitle>{project.name}</StyledModalTitle>;
  }, [project]);

  const vehicleTypes = useMemo<Partial<KIPVehicleType>[]>(() => {
    if (!project || grouped?.value !== 1) {
      return [];
    }

    return getVehicleTypes(project.id);
  }, [getVehicleTypes, grouped, project]);

  const partners = useMemo<Partial<KIPPartners>[]>(() => {
    if (!project || grouped?.value !== 2) {
      return [];
    }

    return getPartners(project.id);
  }, [getPartners, grouped, project]);

  const characteristics = useMemo<Partial<KIPCharacteristics>[]>(() => {
    if (!project || grouped?.value !== 3) {
      return [];
    }

    return getCharacteristics(project.id);
  }, [getCharacteristics, grouped, project]);

  const vehicles = useMemo<Partial<KIPVehicle>[][]>(() => {
    if (!project || grouped?.value) {
      return [];
    }

    return convertArray(getVehicles(project.id!));
  }, [grouped, getVehicles, project]);

  const onChangeGroup = useCallback(
    (value: SelectOption<null> | null, _: any) => {
      setGrouped(value);
    },
    []
  );

  const onAlertClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const headerContent = useMemo(() => {
    if (!project) {
      return null;
    }

    return (
      <StyledHeader>
        <p>{date}</p>
        <StyledInfoRow>
          <StyledInfo>
            <Chip
              bold={true}
              color="primaryDark"
              text={`${project.vehiclesQty} ТС`}
              textSize="extra"
            />
            <StyledLabel>Количество техники</StyledLabel>
          </StyledInfo>
          <StyledInfo>
            <Chip
              bold={true}
              text={project.workLoad ? `${project.workLoad.toFixed(0)} %` : "-"}
              color="warning"
              textSize="extra"
            />
            <StyledLabel>Загруженность</StyledLabel>
          </StyledInfo>
          <StyledInfo>
            <Chip
              bold={true}
              text={priceFormat(project.costAmount.toFixed(0))}
              color="info"
              textSize="extra"
            />
            <StyledLabel>Сумма затрат</StyledLabel>
          </StyledInfo>
          <StyledInfo>
            <Chip
              bold={true}
              text={priceFormat(project.unusedAmount.toFixed(0))}
              color="danger"
              textSize="extra"
            />
            <StyledLabel>Неосвоенный бюджет</StyledLabel>
          </StyledInfo>
        </StyledInfoRow>
        <AlertRow>
          <Alert
            text={
              "Чем лучше организованы погрузочно-разгрузочные работы и меньше непроизводительные простои, тем выше коэффициент использования рабочего времени"
            }
            onClick={onAlertClick}
          />
          <div id="select">
            <Select<any>
              label="Групировать по"
              name="group"
              onChange={onChangeGroup}
              value={grouped}
              valueKey="id"
              labelKey="name"
              options={groupTypes}
            />
          </div>
        </AlertRow>
      </StyledHeader>
    );
  }, [date, grouped, onAlertClick, onChangeGroup, project]);

  if (!open && !project) {
    return null;
  }

  return (
    <>
      <Modal
        title={modalTitle}
        open={open}
        onClose={onClose}
        customSize="calc(100% - 50px)"
        headerContent={headerContent}
        contentStyle={{
          boxSizing: "border-box",
        }}
      >
        <Grid
          gap={0}
          style={{
            marginTop: -16,
            marginLeft: -24,
            marginRight: -24,
          }}
        >
          {vehicles.map((item, itemIndex) => (
            <StyledBox key={itemIndex.toString()}>
              <StyledGrid
                column={item.length === 1 ? 1 : 3}
                width={item.length === 1 ? 800 : 443}
              >
                {item.map((vehicle, vIndex) => (
                  <VehicleStatistics
                    vehicle={vehicle as KIPVehicle}
                    projectId={project?.id}
                    key={vehicle.id}
                  />
                ))}
              </StyledGrid>
            </StyledBox>
          ))}
          {vehicleTypes.map((vehicleType) => (
            <Grouped
              key={vehicleType.id}
              title={vehicleType.name!}
              data={vehicleType as KIPVehicleType}
              project={project}
            />
          ))}
          {partners.map((partner) => (
            <Grouped
              key={partner.id}
              title={partner.name!}
              data={partner as KIPPartners}
              project={project}
            />
          ))}
          {characteristics.map((ch) => (
            <Grouped
              key={ch.id}
              title={ch.name!}
              data={ch as KIPCharacteristics}
              project={project}
            />
          ))}
        </Grid>
      </Modal>

      <ModalKIPInformation open={showModal} onClose={onModalClose} />
    </>
  );
}
