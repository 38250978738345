import { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { TemplateSearch } from "@app/templates";
import { Combobox, DateTimePicker, DefaultObject, Grid } from "@app/components";
import {
  Company,
  Project,
  VehicleJournalDetail,
  VehicleJournalList,
  VehicleType,
  VehicleZhurnalState,
  VehicleZhurnalStateName,
} from "@app/models";
import {
  getCompaniesFiltered,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getVehicleJournalDetailByDate,
  getVehicleJournalDetailByPartner,
  getVehicleJournalDetailByTechnic,
  getVehicleJournalListByDate,
  getVehicleJournalListByPartner,
  getVehicleJournalListByTechnic,
  getVehicleTypes,
  PageableParams,
} from "@app/api";
import "./styles.scss";
import {
  companyLabelKeys,
  dateFormat,
  emptyPageableResponse,
  enumToArray,
  getDateFilterValue,
  getFilterValues,
  getFirstDayOfMonth,
  isDispatcher,
} from "@app/helpers";
import { ModalJournalItem } from "@app/modals";
import { useUser } from "@app/providers";
import { subMonths } from "date-fns";
import {
  IconCancel20,
  IconCheck20,
  IconQuestion20,
  IconWarning20,
} from "@app/icons";
import { theme } from "styled-tools";

export enum FILTER_TYPES {
  BY_DATES,
  BY_PARTNERS,
  BY_VEHICLES,
}

interface Props {
  filterTypeIndex: FILTER_TYPES;
}

interface FilterType {
  cellTitle: string;
  chipTitle: string;
  cellData: (val: VehicleJournalList) => string;
}

interface FilterParamsProps {
  pageSize: number;
  companies: Company[] | null;
  partners: Company[] | null;
  vehicleTypes: VehicleType[] | null;
  projects: Project[] | null;
  statuses: DefaultObject<VehicleZhurnalState>[] | null;
  startDate: Date | null;
  endDate: Date | null;
}

const StyledContainer = styled.div`
  height: 100vh;
`;

const StyledHeaderCell = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Header = styled.div`
  padding: 16px 24px 8px;
  background: #ffffff;
`;

const StyledIconCheckContainer = styled.div`
  color: ${theme("color.green")};
`;

const FILTER_STORAGE_KEY = "vehicleJournalFilters";

function ListView({ filterTypeIndex }: Props) {
  const { user } = useUser();
  const filterBy: FilterType[] = useMemo(
    () => [
      {
        cellTitle: "Дата",
        chipTitle: "По датам",
        cellData: (val) => dateFormat(val.workDate, "dd.MM.yyyy"),
      },
      {
        cellTitle: "Контрагент",
        chipTitle: "По контрагентам",
        cellData: (val) => val.partnerName || "",
      },
      {
        cellTitle: "Вид техники",
        chipTitle: "По видам техники",
        cellData: (val) => val.vehicleTypeName || "",
      },
    ],
    []
  );

  const initialFilterParams: FilterParamsProps = {
    pageSize: 20,
    companies: null,
    projects: null,
    partners: null,
    vehicleTypes: null,
    statuses: null,
    startDate: getFirstDayOfMonth(subMonths(new Date(), 1)),
    endDate: new Date(),
  };

  const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
  const parsedFilters = savedFilters
    ? JSON.parse(savedFilters)
    : initialFilterParams;
  if (parsedFilters.startDate) {
    parsedFilters.startDate = new Date(parsedFilters.startDate);
  }
  if (parsedFilters.endDate) {
    parsedFilters.endDate = new Date(parsedFilters.endDate);
  }

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [filterParams, setFilterParams] =
    useState<FilterParamsProps>(parsedFilters);
  const [selectedItem, setSelectedItem] = useState<VehicleJournalList | null>(
    null
  );
  const [journalDetail, setJournalDetail] = useState<
    VehicleJournalDetail[] | null
  >(null);

  const loadCompanies = useMemo(() => {
    return isDispatcher(user!.role) ? getCompaniesFiltered : getMyCompanies;
  }, [user]);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      const filtersToStore = { ...updatedFilters };
      if (filtersToStore.startDate) {
        // @ts-ignore
        filtersToStore.startDate = filtersToStore.startDate.toISOString();
      }
      if (filtersToStore.endDate) {
        // @ts-ignore
        filtersToStore.endDate = filtersToStore.endDate.toISOString();
      }

      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filtersToStore));
    },
    [filterParams]
  );

  const tableLabels = useMemo(
    () => [
      `${filterBy[filterTypeIndex].cellTitle}`,
      "Организация",
      "Проект",
      <StyledHeaderCell>
        <StyledIconCheckContainer>
          <IconCheck20 />
        </StyledIconCheckContainer>
        <span>Подтверждено</span>
      </StyledHeaderCell>,
      <StyledHeaderCell>
        <IconCancel20 />
        <span>Не работал</span>
      </StyledHeaderCell>,
      <StyledHeaderCell>
        <IconWarning20 />
        <span>На проверке</span>
      </StyledHeaderCell>,
      <StyledHeaderCell>
        <IconQuestion20 />
        <span>Не определено</span>
      </StyledHeaderCell>,
    ],
    [filterBy, filterTypeIndex]
  );

  const mapTableData = useCallback(
    (item: any) => {
      return [
        filterBy[filterTypeIndex].cellData(item),
        item.companyName,
        item.projectName,
        item.confirmedCount,
        item.didNotWorkCount,
        item.onConfirmationCount,
        item.saveCount,
      ];
    },
    [filterBy, filterTypeIndex]
  );

  const getTableList = useCallback(
    async (_params: PageableParams) => {
      const params = {
        pageSize: filterParams.pageSize,
        companyIds: getFilterValues(filterParams.companies),
        projectIds: getFilterValues(filterParams.projects),
        partnerIds: getFilterValues(filterParams.partners),
        vehicleTypeIds: getFilterValues(filterParams.vehicleTypes),
        StateIds: getFilterValues(filterParams.statuses),
        StartDate: getDateFilterValue(filterParams.startDate),
        EndDate: getDateFilterValue(filterParams.endDate),
        ..._params,
      };

      switch (filterTypeIndex) {
        case 0:
          return await getVehicleJournalListByDate(params);
        case 1:
          return await getVehicleJournalListByPartner(params);
        case 2:
          return await getVehicleJournalListByTechnic(params);
        default:
          return emptyPageableResponse;
      }
    },
    [filterParams, filterTypeIndex]
  );

  const onRowClick = async (item: any) => {
    const { companyId, projectId, workDate, partnerId, vehicleTypeId } = item;

    setSelectedItem(item);
    setShowDetail(true);

    let response = null;
    const params = {
      companyId,
      projectId,
      workDate: workDate?.substring(0, 10),
      partnerId,
      vehicleTypeId,
      startDate: filterParams.startDate
        ? dateFormat(filterParams.startDate, "yyyy-MM-dd")
        : null,
      endDate: filterParams.endDate
        ? dateFormat(filterParams.endDate, "yyyy-MM-dd")
        : null,
      stateIds: getFilterValues(filterParams.statuses),
    };

    switch (filterTypeIndex) {
      case 0:
        response = await getVehicleJournalDetailByDate(params);
        break;
      case 1:
        response = await getVehicleJournalDetailByPartner(params);
        break;
      case 2:
        response = await getVehicleJournalDetailByTechnic(params);
        break;
      default:
        return;
    }

    setJournalDetail(response.data);
  };

  const onModalClose = async (needUpdate?: boolean) => {
    if (needUpdate) {
      await getTableList({
        pageNumber: 1,
      });
    } else {
      setShowDetail(false);
      setSelectedItem(null);
      setJournalDetail(null);
    }
  };

  const getFilterField = useMemo(() => {
    switch (filterTypeIndex) {
      case FILTER_TYPES.BY_DATES:
        return <div />;
      case FILTER_TYPES.BY_PARTNERS:
        return (
          <Combobox<Company>
            label="Контрагент"
            name="partners"
            onChange={onFilterChange}
            values={filterParams.partners}
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={loadCompanies}
          />
        );
      case FILTER_TYPES.BY_VEHICLES:
        return (
          <Combobox<VehicleType>
            label="Вид техники"
            name="vehicleTypes"
            onChange={onFilterChange}
            values={filterParams.vehicleTypes}
            loadData={getVehicleTypes}
          />
        );
    }
  }, [
    filterParams.partners,
    filterParams.vehicleTypes,
    filterTypeIndex,
    loadCompanies,
    onFilterChange,
  ]);

  return (
    <StyledContainer>
      <Header>
        <Grid columns={5}>
          <Combobox<Company>
            label="Организация"
            name="companies"
            onChange={onFilterChange}
            values={filterParams.companies}
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={loadCompanies}
          />
          <Combobox<Project>
            label="Проект"
            name="projects"
            onChange={onFilterChange}
            values={filterParams.projects}
            loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
          />
          <Combobox<DefaultObject<VehicleZhurnalState>>
            label="Статус"
            name="statuses"
            values={filterParams.statuses}
            options={enumToArray(VehicleZhurnalStateName)}
            onChange={onFilterChange}
          />
          <DateTimePicker
            label="Дата начала"
            name="startDate"
            onChange={onFilterChange}
            value={filterParams.startDate ?? new Date()}
            hideTime
          />
          <DateTimePicker
            label="Дата завершения"
            name="endDate"
            onChange={onFilterChange}
            value={filterParams.endDate ?? new Date()}
            hideTime
          />
          {getFilterField}
        </Grid>
      </Header>

      <TemplateSearch
        getData={getTableList}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        toolbarVisible={false}
        onClick={onRowClick}
      />
      <ModalJournalItem
        data={journalDetail}
        headerInfo={selectedItem}
        open={showDetail}
        filterTypeIndex={filterTypeIndex}
        onClose={onModalClose}
      />
    </StyledContainer>
  );
}

export default memo(ListView);
