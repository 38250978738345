import instance from "./instance";
import { APIResponse } from "./interfaces";
import { FileSimple } from "@app/models";

export function getFileById(id: string, isAttachment = false): Promise<Blob> {
  const params = { isAttachment };
  return instance()
    .get(`/file/${id}`, {
      params,
      responseType: isAttachment ? "json" : "blob",
    })
    .then((response) => response.data);
}

export const uploadFile = (formData: FormData): Promise<APIResponse<any>> => {
  return instance()
    .post("/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export const uploadFiles = (
  fileList: File[]
): Promise<APIResponse<FileSimple[]>> => {
  const formData = new FormData();
  fileList.forEach((file) => {
    formData.append("files", file);
  });
  return instance()
    .post("/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};
