import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DocumentFile,
  Modal,
  ModalProps,
} from "@app/components";
import styled from "styled-components";
import { FileSimple, VehicleJournalFile } from "@app/models";
import {
  addVehicleJournalFile,
  getVehicleFileTypes,
  JournalUploadFile,
  removeVehicleFile,
  uploadFiles,
} from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { IconDocumentFile40 } from "@app/icons";

interface Props extends Omit<ModalProps, "title" | "onClose"> {
  vehicleJournalId: string;
  files: VehicleJournalFile[];
  updateJournalData: (updateFiles?: VehicleJournalFile[]) => void;
  onClose: () => void;
}

const StyledDropZone = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;
  margin-bottom: 24px;

  input {
    display: none;
  }
`;

const StyledDropZoneTitle = styled.div`
  color: #636463;

  .link {
    color: #4f8bff;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const StyledDropZoneCaption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
`;

const StyledFileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

function ModalVehicleJournalFiles(props: Props) {
  const { showNotification } = useNotification();
  const {
    open,
    files: initialFiles,
    vehicleJournalId,
    onClose,
    updateJournalData,
    // ...modalProps
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<FileSimple[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<string>();

  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback((): void => {
    inputRef.current!.click();
  }, []);

  const onChangeInput = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const fileList = e.target.files;
      if (!fileList) {
        return;
      }
      const { data } = await uploadFiles(Array.from(fileList));

      setUploadedFiles([...uploadedFiles, ...data]);
      e.target.value = "";
    },
    [uploadedFiles]
  );

  const onFileDelete = useCallback((fileId: string) => {
    setSelectedFileId(fileId);
    setConfirmDialogOpen(true);
  }, []);

  // Удаление загруженных (несохраненных) файлов
  const onUploadedFileRemove = useCallback(
    (fileId: string) => {
      const filtered = uploadedFiles.filter((f) => f.id !== fileId);
      setUploadedFiles(filtered);
    },
    [uploadedFiles]
  );

  // Удаление файлов из журнала
  const onConfirmDelete = useCallback(async (): Promise<void> => {
    try {
      const findIndex = initialFiles.findIndex((f) => f.id === selectedFileId);
      if (findIndex > -1) {
        const { vehicleJournalFileId } = initialFiles[findIndex];
        await removeVehicleFile(vehicleJournalFileId);
        showNotification({
          message: "Файл удален",
          variant: "success",
        });

        initialFiles.splice(findIndex, 1);
        updateJournalData([...initialFiles]);
      } else {
        showNotification({
          message: "Файл не удален",
          variant: "error",
        });
      }
    } catch (error) {
      setConfirmDialogOpen(false);
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<any>),
        variant: "error",
      });
    }
    setConfirmDialogOpen(false);
  }, [initialFiles, selectedFileId, showNotification, updateJournalData]);

  // Добавление файлов в журнал
  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { data: vehicleFileTypes } = await getVehicleFileTypes();
      const [type] = vehicleFileTypes;

      if (!type?.id) {
        return new Error("Типы файлов техники не найдены");
      }

      const journalFiles: JournalUploadFile[] = uploadedFiles.map((f) => ({
        fileId: f.id,
        typeId: type.id,
      }));

      const { data: vehicleJournalFiles } = await addVehicleJournalFile({
        vehicleJournalId,
        files: journalFiles,
      });

      showNotification({
        message: "Файлы успешно добавлены",
        variant: "success",
      });

      setUploadedFiles([]);
      updateJournalData([...initialFiles, ...vehicleJournalFiles]);
      onClose();
    } catch (error) {
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<any>),
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    initialFiles,
    onClose,
    uploadedFiles,
    updateJournalData,
    showNotification,
    vehicleJournalId,
  ]);

  const onConfirmModalClose = () => {
    setConfirmDialogOpen(false);
  };

  const onModalClose = () => {
    if (uploadedFiles.length > 0) {
      showNotification({
        message: "Некоторые файлы не были добавлены в журнал",
        variant: "warning",
      });
    }
    onClose();
  };

  const actions = useMemo(
    () => [<Button text="Сохранить" onClick={onSave} showLoader={loading} />],
    [onSave, loading]
  );

  return (
    <>
      <Modal
        open={open}
        title="Документы"
        size="small"
        zIndex={10000}
        actions={actions}
        onClose={onModalClose}
      >
        <StyledDropZone>
          <StyledDropZoneTitle>
            <span className="link" onClick={onClick}>
              Загрузите
            </span>{" "}
            документ или перетащите его сюда
          </StyledDropZoneTitle>
          <StyledDropZoneCaption>
            Форматы: doc, docx, pdf, png, jpeg; Максимальный размер: 3 мб
          </StyledDropZoneCaption>
          <input
            type="file"
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
            ref={inputRef}
            onChange={onChangeInput}
            multiple={false}
          />
          {uploadedFiles.map((item) => (
            <DocumentFile
              key={item.id}
              id={item.id}
              name={item.name || ""}
              icon={<IconDocumentFile40 color={"#DA616C"} />}
              onRemove={onUploadedFileRemove}
            />
          ))}
        </StyledDropZone>

        {!!initialFiles.length && <p>Сохраненные файлы</p>}
        <StyledFileList>
          {initialFiles.map((item) => (
            <DocumentFile
              key={item.id}
              id={item.id}
              name={item.name || ""}
              previewEnabled
              onRemove={onFileDelete}
            />
          ))}
        </StyledFileList>
      </Modal>

      <Dialog
        open={confirmDialogOpen}
        title="Подтвердите удаление"
        subtitle="Вы действительно хотите удалить загруженный ранее файл?"
        loading={loading}
        onConfirm={onConfirmDelete}
        onClose={onConfirmModalClose}
      />
    </>
  );
}

export default memo(ModalVehicleJournalFiles);
